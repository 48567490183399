import {FC, useLayoutEffect, useRef} from "react";
import tailwindClassnames from "@/modules/tailwind/tailwindClassnames";

export interface ParagraphProps {
  type: 'paragraph' | 'richParagraph'
  value: string
  spanClasses: object
  classes: object
}

const Paragraph: FC<ParagraphProps> = ({
  type,
  value,
  spanClasses,
  classes
}) => {

  const paragraphRef = useRef(null as HTMLElement | null);

  useLayoutEffect(() => {
    if (paragraphRef.current) {
      // NOTE Add Classes to Links
      const aTags = paragraphRef?.current?.querySelectorAll('a')
      Array.from(aTags).map((tag: any, index: number) => {
        tag.classList.add('text-[#0066ff]')
      })
      // NOTE Add Classes to Span
      const spanTags = paragraphRef?.current?.querySelectorAll('span')
      Array.from(spanTags).map((tag: any, index: number) => {
        tag.classList.add(tailwindClassnames(spanClasses))
      })
    }
  });

  return (
    <>
      {type === 'paragraph' && (
        <p
          className={tailwindClassnames(classes)}
        >
          {value}
        </p>
      ) || type === 'richParagraph' && (
        <p
          // @ts-ignore TODO
          ref={paragraphRef}
          dangerouslySetInnerHTML={{__html: value}}
          className={tailwindClassnames(classes)}
        />
      )}
    </>
  )

}

export default Paragraph
