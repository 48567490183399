import {FC, ReactNode} from "react";
import tailwindClassnames from "@/modules/tailwind/tailwindClassnames";

interface SectionProps {
  anchor?: string
  classes?: object
  childClasses?: object
  children?: ReactNode | ReactNode[]
}

const Section: FC<SectionProps> = ({
  anchor,
  classes,
  childClasses,
  children,
}) => {

  const RenderSectionChildWrapper: FC<any> = ({
    background,
    classes,
    children,
  }) => {
    return (
      <div
        className={tailwindClassnames(classes)}
      >
        {children}
      </div>
    )
  }

  return childClasses ? (
    <section
      data-element="section"
      id={!!anchor && anchor}
      // @ts-ignore
      className={tailwindClassnames(classes)}
    >
      <RenderSectionChildWrapper
        classes={childClasses}
      >
        {children}
      </RenderSectionChildWrapper>
    </section>
  ) : (
    <section
      data-element="section"
      id={!!anchor && anchor}
      // @ts-ignore
      className={tailwindClassnames(classes)}
    >
      {children}
    </section>
  )

}

export default Section
