import {FC} from "react";
import tailwindClassnames from "@/modules/tailwind/tailwindClassnames";

export interface HeadingProps {
  type: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  value: string
  classes: object
}

const Heading: FC<HeadingProps> = ({
  type: Component,
  value,
  classes
}) => {

  return (
    <Component
      className={tailwindClassnames(classes)}
    >
      {value}
    </Component>
  )
}

export default Heading
