import {FC, useLayoutEffect, useRef} from "react";
import Image from 'next/image';
import tailwindClassnames from "@/modules/tailwind/tailwindClassnames";

export interface NextImageLayoutProps {
  intrinsic: string
  fixed: string
  responsive: string
  fill: string
  raw: string
}

export interface ImageProperties {
  alt: string
  // layout: NextImageLayoutProps
  layout: string
  objectFit: string
  src: string
  height?: number | string
  width?: number | string
}

export interface Props {
  imageProperties: ImageProperties
  imageClasses?: object
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const ImageElement: FC<Props> = ({
  imageProperties,
  imageClasses
}) => {

  const imageRef = useRef(null as HTMLElement | null);

  useLayoutEffect(() => {
    if (imageRef.current && imageClasses) {
      imageRef.current.className = tailwindClassnames(imageClasses)
    }
  });
  // console.log(
  //   'imageProperties',
  //   // imageProperties,
  //   imageProperties.layout,
  //   imageProperties.layout === "dimensions"
  // )
  return (
    <>
      <div
        // @ts-ignore TODO
        ref={imageRef}
        // className="hey"  // classNames are set with useLayoutEffect
      >
        <Image
          // @ts-ignore TODO
          layout={imageProperties.layout}
          width={
            imageProperties.layout === "intrinsic" || imageProperties.layout === "fixed" || imageProperties.layout === "responsive" || imageProperties.layout === "raw" ?
            imageProperties.width : undefined
          }
          height={
            imageProperties.layout === "intrinsic" || imageProperties.layout === "fixed" || imageProperties.layout === "responsive" || imageProperties.layout === "raw" ?
            imageProperties.height : undefined
          }
          src={imageProperties.src}
          alt={imageProperties.alt}
          // className={classNames(
          //   imageProperties.objectFit === "cover" ? "object-cover" : ""
          // )}
        />
      </div>
    </>
  )

}

export default ImageElement
