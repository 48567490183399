import {FC} from "react";
import tailwindClassnames from "@/modules/tailwind/tailwindClassnames";

export interface TextProps {
  type: 'span' | 'p'
  value: string
  classes: object
}

const Text: FC<TextProps> = ({
  type: Component,
  value,
  classes
}) => {

  return (
    <Component
      className={tailwindClassnames(classes)}
    >
      {value}
    </Component>
  )
}

export default Text
