import {FC, useLayoutEffect, useRef} from "react";

export interface RichListProps {
  type: 'ul' | 'ol'
  value: string[]
}

const RichList: FC<RichListProps> = ({
  type: Component,
  value
}) => {

  const richListRef = useRef(null as HTMLElement | null);

  useLayoutEffect(() => {
    if (richListRef.current) {
      // NOTE Add Classes to Links
      const aTags = richListRef?.current?.querySelectorAll('li a')
      Array.from(aTags).map((tag: any, index: number) => {
        tag.classList.add('text-[#1000ff]')
      })
      // NOTE Add Classes to Span
      const spanTags = richListRef?.current?.querySelectorAll('li span')
      Array.from(spanTags).map((tag: any, index: number) => {
        tag.classList.add('')
      })
    }
  });

  return (
    <Component
      // @ts-ignore TODO
      ref={richListRef}
      className="list-inside list-disc marker:text-[#400068] text-gray-900 text-md tracking-tight leading-7"
    >
      {value.map((item: any, index: number) => {

        return !item.includes("<ul") ?
          <>
            <li
              key={`list-item-${index}`}
              dangerouslySetInnerHTML={{__html: item}}
            />
          </>
        : <>
            <div
              className="ml-6 mt-2"
              key={`list-item-${index}`}
              dangerouslySetInnerHTML={{__html: item}}
            />
          </>
      })}
    </Component>
  )
}

export default RichList
