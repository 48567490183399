import {FC, ReactNode} from "react";
import tailwindClassnames from "@/modules/tailwind/tailwindClassnames";

export interface HeadingProps {
  classes: object
  children: ReactNode | ReactNode[]
}

const FlexCol: FC<HeadingProps> = ({
  classes,
  children
}) => {

  return (
    <div
      className={tailwindClassnames(classes)}
    >
      {children}
    </div>
  )
}

export default FlexCol
