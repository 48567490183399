import {FC, ReactNode} from "react";
import tailwindClassnames from "@/modules/tailwind/tailwindClassnames";

export interface ContainerProps {
  classes?: object
  children: ReactNode | ReactNode[]
}

const Container: FC<ContainerProps> = ({
  classes,
  children
}) => {

  return (
    <div
      className={classes && tailwindClassnames(classes)}
    >
      {children}
    </div>
  )
}

export default Container
