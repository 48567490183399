import {FC, ReactNode} from "react";
import tailwindClassnames from "@/modules/tailwind/tailwindClassnames";

export interface GridRowProps {
  classes?: object
  children: ReactNode | ReactNode[]
}

const GridRow: FC<GridRowProps> = ({
  classes,
  children
}) => {

  return (
    <div
      className={classes && tailwindClassnames(classes)}
    >
      {children}
    </div>
  )
}

export default GridRow
