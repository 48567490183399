import {FC, ReactNode} from "react";
import SectionLayout from "@/layouts/SectionLayout";
import tailwindClassnames from "@/modules/tailwind/tailwindClassnames";
import GridRow from "@/components/centralbrisbanedental/elements/grid/GridRow/GridRow";
import GridCol from "@/components/centralbrisbanedental/elements/grid/GridCol/GridCol";
import Container from "@/components/centralbrisbanedental/elements/Container/Container";
import ImageElement from "@/components/centralbrisbanedental/content/Image/Image";

interface DynamicAttributeClassNames {
  backgroundColor?: string,
  headingColor?: string,
  headingLineTwoColor?: string,
}

interface CTABannerStandardProps {
  dentist: string
  heading?: string
  value?: string
  buttonText?: string
  buttonDestination?: string
  hasInset?: boolean
  insetClasses?: object
  buttonClasses?: object
  classes?: object
  children?: ReactNode | ReactNode[]
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const CTABannerSplitTiles: FC<CTABannerStandardProps> = ({
                                                           dentist,
                                                           heading,
                                                           value,
                                                           buttonText,
                                                           buttonDestination,
                                                           hasInset,
                                                           insetClasses,
                                                           buttonClasses,
                                                           classes,
                                                           children
                                                         }) => {

  return (
    <SectionLayout classes={classes && classes}>

      {/* This is a decorative inset */}
      {hasInset && <div
        className={insetClasses && tailwindClassnames(insetClasses)}
        aria-hidden="true"
      />}

      <div className="max-w-7xl mx-auto lg:px-8">

        <GridRow
          classes={{
            display: 'lg:grid lg:grid-cols-12'
          }}
        >
          <GridCol
            classes={{
              position: 'relative',
              grid: 'lg:col-start-2 lg:row-start-1 lg:col-span-2',
              padding: 'lg:py-16',
              zIndex: 'z-[1]',
              background: 'lg:bg-transparent'
            }}
          >
            {/* Mobile Only Inset */}
            <div className="absolute inset-x-0 h-1/2 lg:hidden" aria-hidden="true"/>

            <Container
              classes={{
                width: 'max-w-md sm:max-w-3xl lg:max-w-none',
                alignment: 'mx-auto text-center sm:text-left',
                padding: 'px-4 sm:px-6 lg:p-0'
              }}
            >

              <ImageElement
                imageProperties={{
                  alt: dentist === 'vincent' ? 'dr vincent wan' : 'dr allen wan',
                  height: 305,
                  layout: 'fixed',
                  objectFit: 'cover',
                  src: `/static/images/team/${dentist === 'vincent' ? 'dr-vincent-wan.png' : 'dr-allen-wan.png'}`,
                  width: 214
                }}
                imageClasses={{
                  align: 'items-center',
                  objectFit: 'object-cover',
                  objectPosition: 'object-center',
                  borderRadius: 'rounded-3xl',
                  aspectRatio: 'aspect-w-10 aspect-h-6 sm:aspect-w-2 sm:aspect-h-1 lg:aspect-w-1',
                }}
              />

            </Container>

          </GridCol>

          <GridCol
            classes={{
              position: 'relative',
              display: 'lg:grid',
              grid: 'lg:col-start-3 lg:row-start-1 lg:col-span-9 lg:grid-cols-10',
              padding: 'lg:py-16',
              // background: 'bg-[#f3f3f3]',
              background: dentist === 'vincent' ? 'bg-[#17aaab]' : 'bg-[#FF6600]',
              borderRadius: 'lg:rounded-3xl',
              itemAlignment: 'lg:items-center',
              shadow: 'shadow-xl'
            }}
          >

            {/* SVG Dots */}
            <>
              {/*  <div className="hidden absolute inset-0 overflow-hidden rounded-3xl lg:block" aria-hidden="true">*/}

              {/*  <svg*/}
              {/*    className="absolute bottom-full left-full transform translate-y-1/3 -translate-x-2/3 xl:bottom-auto xl:top-0 xl:translate-y-0"*/}
              {/*    width={404}*/}
              {/*    height={192}*/}
              {/*    fill="none"*/}
              {/*    viewBox="0 0 404 192"*/}
              {/*    aria-hidden="true"*/}
              {/*  >*/}
              {/*    <defs>*/}
              {/*      <pattern*/}
              {/*        id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"*/}
              {/*        x={0}*/}
              {/*        y={0}*/}
              {/*        width={20}*/}
              {/*        height={20}*/}
              {/*        patternUnits="userSpaceOnUse"*/}
              {/*      >*/}
              {/*        <rect x={0} y={0} width={4} height={4} className="text-black" fill="currentColor" />*/}
              {/*      </pattern>*/}
              {/*    </defs>*/}
              {/*    <rect width={404} height={384} fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />*/}
              {/*  </svg>*/}

              {/*  <svg*/}
              {/*    className="absolute top-full transform -translate-y-1/3 -translate-x-1/3 xl:-translate-y-1/2"*/}
              {/*    width={404}*/}
              {/*    height={192}*/}
              {/*    fill="none"*/}
              {/*    viewBox="0 0 404 192"*/}
              {/*    aria-hidden="true"*/}
              {/*  >*/}
              {/*    <defs>*/}
              {/*      <pattern*/}
              {/*        id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"*/}
              {/*        x={0}*/}
              {/*        y={0}*/}
              {/*        width={20}*/}
              {/*        height={20}*/}
              {/*        patternUnits="userSpaceOnUse"*/}
              {/*      >*/}
              {/*        <rect x={0} y={0} width={4} height={4} className="text-black" fill="currentColor" />*/}
              {/*      </pattern>*/}
              {/*    </defs>*/}
              {/*    <rect width={404} height={384} fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />*/}
              {/*  </svg>*/}

              {/*</div>*/}
            </>

            {/* Content */}
            <GridCol
              classes={{
                position: 'relative',
                width: 'max-w-md sm:max-w-3xl lg:max-w-none',
                grid: 'lg:col-start-3 lg:col-span-7',
                padding: 'py-12 px-4 sm:py-16 sm:px-6 lg:p-0 space-y-6',
                background: dentist === 'vincent' ? 'bg-[#17aaab]' : 'bg-[#FF6600]',
                borderRadius: 'lg:rounded-3xl',
                alignment: 'mx-auto',
                itemAlignment: 'lg:items-center'
              }}
            >

              {heading && <h2
                className={classNames(
                  "text-3xl font-extrabold text-black",
                )}
                id="join-heading"
              >
                {heading}
              </h2>}

              {value && <p
                className={classNames(
                  "text-lg font-bold text-black",
                )}
              >
                {value}
              </p>}

              {buttonText && (
                <a
                  className={classNames(
                    dentist === 'vincent' ? 'bg-[#ff6600]' : 'bg-[#17aaab]',
                    "block w-full py-3 px-5 text-center border border-transparent rounded-md shadow-md text-base font-medium text-black sm:inline-block sm:w-auto no-underline",
                  )}
                  href={buttonDestination}
                >
                  {buttonText}
                </a>
              )}

            </GridCol>

          </GridCol>
        </GridRow>

      </div>

    </SectionLayout>
  )
}

export default CTABannerSplitTiles
